import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import multiguard from "vue-router-multiguard";
Vue.use(VueRouter);

// Logic for login gard for routes
const loggedIn = function (to, from, next) {
  if (!store.state.auth.data.token) {
    next({
      name: "PageAuthSignin",
    });
  } else {
    next();
  }
};

const loggedOut = function (to, from, next) {
  if (store.state.auth.data.token) {
    next({
      name: "PageDashboard",
    });
  } else {
    next();
  }
};

// all route logics
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutApp.vue"),
    beforeEnter: multiguard([loggedIn]),
    children: [
      // dashboard
      {
        path: "/dashboard",
        name: "PageDashboard",
        component: () =>
          import(
            /* webpackChunkName: "page-PageDashboard" */ "@/views/home/PageDashboard.vue"
          ),
        meta: {
          title: "Dashboard",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: true,
              to: { name: "PageDashboard" },
            },
          ],
        },
      },

      // Users
      {
        path: "/users",
        name: "PageUserList",
        component: () =>
          import(
            /* webpackChunkName: "page-user-list" */ "@/views/users/PageUserList.vue"
          ),
        meta: {
          title: "Users",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Users",
              disabled: true,
              to: { name: "PageUserList" },
            },
          ],
        },
      },

      // FAQs
      {
        path: "/faqs",
        name: "PageFAQList",
        component: () =>
          import(
            /* webpackChunkName: "page-faq-list" */ "@/views/FAQs/PageFAQList.vue"
          ),
        meta: {
          title: "FAQs",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "FAQs",
              disabled: true,
              to: { name: "PageFAQList" },
            },
          ],
        },
      },

      // Heros
      {
        path: "/heros",
        name: "PageHeroList",
        component: () =>
          import(
            /* webpackChunkName: "page-faq-list" */ "@/views/Heros/PageHeroList.vue"
          ),
        meta: {
          title: "Heros",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Heros",
              disabled: true,
              to: { name: "PageHeroList" },
            },
          ],
        },
      },

      // Adds
      {
        path: "/adds",
        name: "PageAddList",
        component: () =>
          import(
            /* webpackChunkName: "page-add-list" */ "@/views/Adds/PageAddList.vue"
          ),
        meta: {
          title: "Adds",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Adds",
              disabled: true,
              to: { name: "PageAddList" },
            },
          ],
        },
      },

      // Why
      {
        path: "/why",
        name: "PageWhyList",
        component: () =>
          import(
            /* webpackChunkName: "page-why-list" */ "@/views/why/PageWhyList.vue"
          ),
        meta: {
          title: "Why",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Why",
              disabled: true,
              to: { name: "PageWhyList" },
            },
          ],
        },
      },

      // Who
      {
        path: "/who",
        name: "PageWhoList",
        component: () =>
          import(
            /* webpackChunkName: "page-who-list" */ "@/views/who/PageWhoList.vue"
          ),
        meta: {
          title: "Who",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Who",
              disabled: true,
              to: { name: "PageWhoList" },
            },
          ],
        },
      },

      // Categories
      {
        path: "/categories",
        name: "PageCategoryList",
        component: () =>
          import(
            /* webpackChunkName: "page-categories-list" */ "@/views/categories/PageCategoryList.vue"
          ),
        meta: {
          title: "Main Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Main Categories",
              disabled: true,
              to: { name: "PageCategoryList" },
            },
          ],
        },
      },
      {
        path: "/categories/new",
        name: "PageNewCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-new-category" */ "@/views/categories/PageNewCategory.vue"
          ),
        meta: {
          title: "Main Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Main Categories",
              disabled: false,
              to: { name: "PageCategoryList" },
            },
            {
              text: "New",
              disabled: true,
              to: { name: "PageNewCategory" },
            },
          ],
        },
      },
      {
        path: "/categories/:id",
        name: "PageViewCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-view-category" */ "@/views/categories/PageViewCategory.vue"
          ),
        meta: {
          title: "Main Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Main Categories",
              disabled: false,
              to: { name: "PageCategoryList" },
            },
            {
              text: "View",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
      {
        path: "/categories/:id/edit",
        name: "PageEditCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-category" */ "@/views/categories/PageEditCategory.vue"
          ),
        meta: {
          title: "Main Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Main Categories",
              disabled: false,
              to: { name: "PageCategoryList" },
            },
            {
              text: "Edit",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },

      // Sub Categories
      {
        path: "/sub-categories",
        name: "PageSubCategoryList",
        component: () =>
          import(
            /* webpackChunkName: "page-sub-categories-list" */ "@/views/subCategories/PageSubCategoryList.vue"
          ),
        meta: {
          title: "Sub Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Sub Categories",
              disabled: true,
              to: { name: "PageSubCategoryList" },
            },
          ],
        },
      },
      {
        path: "/sub-categories/new",
        name: "PageNewSubCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-new-sub-category" */ "@/views/subCategories/PageNewSubCategory.vue"
          ),
        meta: {
          title: "Sub Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Sub Categories",
              disabled: false,
              to: { name: "PageSubCategoryList" },
            },
            {
              text: "New",
              disabled: true,
              to: { name: "PageNewSubCategory" },
            },
          ],
        },
      },
      {
        path: "/sub-categories/:id",
        name: "PageViewSubCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-view-sub-category" */ "@/views/subCategories/PageViewSubCategory.vue"
          ),
        meta: {
          title: "Sub Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Sub Categories",
              disabled: false,
              to: { name: "PageSubCategoryList" },
            },
            {
              text: "View",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
      {
        path: "/sub-categories/:id/edit",
        name: "PageEditSubCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-sub-category" */ "@/views/subCategories/PageEditSubCategory.vue"
          ),
        meta: {
          title: "Sub Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Sub Categories",
              disabled: false,
              to: { name: "PageSubCategoryList" },
            },
            {
              text: "Edit",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },

      // Sub Sub Categories
      {
        path: "/sub-sub-categories",
        name: "PageSubSubCategoryList",
        component: () =>
          import(
            /* webpackChunkName: "page-sub-sub-categories-list" */ "@/views/subSubCategories/PageSubSubCategoryList.vue"
          ),
        meta: {
          title: "Sub Sub Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Sub Sub Categories",
              disabled: true,
              to: { name: "PageSubSubCategoryList" },
            },
          ],
        },
      },
      {
        path: "/sub-sub-categories/new",
        name: "PageNewSubSubCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-new-sub-category" */ "@/views/subSubCategories/PageNewSubSubCategory.vue"
          ),
        meta: {
          title: "Sub Sub Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Sub Sub Categories",
              disabled: false,
              to: { name: "PageSubSubCategoryList" },
            },
            {
              text: "New",
              disabled: true,
              to: { name: "PageNewSubSubCategory" },
            },
          ],
        },
      },
      {
        path: "/sub-sub-categories/:id",
        name: "PageViewSubSubCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-view-sub-category" */ "@/views/subSubCategories/PageViewSubSubCategory.vue"
          ),
        meta: {
          title: "Sub Sub Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Sub Sub Categories",
              disabled: false,
              to: { name: "PageSubSubCategoryList" },
            },
            {
              text: "View",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
      {
        path: "/sub-sub-categories/:id/edit",
        name: "PageEditSubSubCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-sub-category" */ "@/views/subSubCategories/PageEditSubSubCategory.vue"
          ),
        meta: {
          title: "Sub Sub Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Sub Sub Categories",
              disabled: false,
              to: { name: "PageSubSubCategoryList" },
            },
            {
              text: "Edit",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },

      // Items
      {
        path: "/items",
        name: "PageItemList",
        component: () =>
          import(
            /* webpackChunkName: "page-items-list" */ "@/views/items/PageItemList.vue"
          ),
        meta: {
          title: "Items",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Items",
              disabled: true,
              to: { name: "PageItemList" },
            },
          ],
        },
      },
      {
        path: "/items/new",
        name: "PageNewItem",
        component: () =>
          import(
            /* webpackChunkName: "page-new-item" */ "@/views/items/PageNewItem.vue"
          ),
        meta: {
          title: "Items",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Items",
              disabled: false,
              to: { name: "PageItemList" },
            },
            {
              text: "New",
              disabled: true,
              to: { name: "PageNewItem" },
            },
          ],
        },
      },
      {
        path: "/items/:id",
        name: "PageViewItem",
        component: () =>
          import(
            /* webpackChunkName: "page-view-item" */ "@/views/items/PageViewItem.vue"
          ),
        meta: {
          title: "Items",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Items",
              disabled: false,
              to: { name: "PageItemList" },
            },
            {
              text: "View",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
      {
        path: "/items/:id/edit",
        name: "PageEditItem",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-item" */ "@/views/items/PageEditItem.vue"
          ),
        meta: {
          title: "Items",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Items",
              disabled: false,
              to: { name: "PageItemList" },
            },
            {
              text: "Edit",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
    ],
  },
  // Auth Pages
  {
    path: "/auth",
    redirect: "/auth/signin",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    beforeEnter: multiguard([loggedOut]),
    children: [
      {
        path: "signin",
        name: "PageAuthSignin",
        component: () =>
          import(
            /* webpackChunkName: "auth-signin" */ "@/views/auth/Signin.vue"
          ),
        meta: {
          title: "Signin",
        },
      },
      {
        path: "otp",
        name: "PageAuthOTP",
        component: () =>
          import(/* webpackChunkName: "auth-otp" */ "@/views/auth/OTP.vue"),
        meta: {
          title: "OTP",
        },
      },
    ],
  },

  // Error Pages
  {
    path: "",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    children: [
      {
        path: "error/expired",
        name: "PageErrorSessionExpired",
        component: () =>
          import(
            /* webpackChunkName: "main-expaired" */ "@/views/error/PageErrorSessionExpired.vue"
          ),
      },
      {
        path: "error/something-went-wrong",
        name: "PageErrorWentWrong",
        component: () =>
          import(
            /* webpackChunkName: "main-something-went-wrong" */ "@/views/error/PageErrorWentWrong.vue"
          ),
      },
      {
        path: "error/not-authorized",
        name: "PageErrorNotAuthorized",
        component: () =>
          import(
            /* webpackChunkName: "main-not-authorized" */ "@/views/error/PageErrorNotAuthorized.vue"
          ),
      },
      {
        path: "/:catchAll(.*)",
        name: "PageErrorNotFound",
        component: () =>
          import(
            /* webpackChunkName: "main-not-found" */ "@/views/error/PageErrorNotFound.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

//page title
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? "dealpartner.lk | " + to.meta.title : "dealpartner.lk";
  });
});

export default router;
